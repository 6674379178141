@import url(https://fonts.googleapis.com/css?family=Arizonia|Kalam|Permanent+Marker&display=swap);
html, body {
  height: 100%;
  font-family: 'Kalam', cursive !important;
  font-size: 1.05em; }
  html .dramatic, body .dramatic {
    font-family: 'Permanent Marker', 'Arizonia', 'Kalam', cursive !important;
    font-size: 3em; }
  html .siteBackground, body .siteBackground {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    background-image: url("https://images.unsplash.com/photo-1557163123-abbafe113238?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=80");
    background-position: 50%; }
  html .MasterContainer, body .MasterContainer {
    padding-top: 5em; }

.NavContainer {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99; }
  .NavContainer * {
    z-index: 100; }

.SplashContentContainer {
  display: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  height: 100%;
  width: 100%; }
  .SplashContentContainer .SplashImage {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-position: 50%;
    background-size: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    z-index: -1 !important; }
  .SplashContentContainer .textContainer {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    color: white; }

.ResumeContainer {
  background-color: rgba(255, 255, 255, 0.85);
  height: auto;
  border: solid 1px black;
  margin-bottom: 5%;
  padding: 2% 5% !important; }
  .ResumeContainer .centerText {
    text-align: center; }
  .ResumeContainer h1, .ResumeContainer h2, .ResumeContainer h3, .ResumeContainer h4, .ResumeContainer h5, .ResumeContainer h6 {
    padding-top: 25px; }
  .ResumeContainer * {
    -webkit-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    border: solid 1px transparent; }
    .ResumeContainer *:hover {
      -webkit-transition: all 500ms ease-in-out;
      transition: all 500ms ease-in-out;
      border: solid 1px rgba(0, 0, 0, 0.1); }

.ProjectsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
  border: solid 1px black;
  margin-bottom: 5%;
  padding: 2% 5% !important; }

.AboutContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
  border: solid 1px black;
  margin-bottom: 5%;
  padding: 2% 5% !important; }

.ContactContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
  border: solid 1px black;
  margin-bottom: 5%;
  padding: 2% 5% !important; }

