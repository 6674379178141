.ContactContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // align-items: center;

    background-color: rgba(255,255,255, .85);

    height: 100%;
    border: solid 1px black;
    margin-bottom: 5%;
    padding: 2% 5% !important;
}