.ResumeContainer {
    // display: flex;
    // flex-direction: column;
    // flex-grow: 1;
    // align-items: center;

    background-color: rgba(255,255,255, .85);

    height: auto;
    // overflow: hidden;
    // overflow: auto;
    border: solid 1px black;
    margin-bottom: 5%;
    padding: 2% 5% !important;

    .centerText {
        text-align: center;
    }

    h1, h2, h3, h4, h5, h6{
        padding-top: 25px;
    }

    * {
        transition: all 500ms ease-in-out;
        border: solid 1px transparent;
        
        &:hover {
            transition: all 500ms ease-in-out;
            border: solid 1px rgba(0,0,0,.1);
        }
    }
    

}