.SplashContentContainer {
    display: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;

    transition: all 1s ease-in-out;

    height: 100%;
    width: 100%;

    .SplashImage {
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
    
        background-position: 50%;
        background-size: cover;
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: all .5s ease-out;
        z-index: -1 !important;
    }

    .textContainer {
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transition: all 1s ease-in-out;
        
        color: white;
    }
}
