@import url('https://fonts.googleapis.com/css?family=Arizonia|Kalam|Permanent+Marker&display=swap');

html, body {
    height: 100%;
    font-family: 'Kalam', cursive !important;
    font-size: 1.05em;

    .dramatic{
        font-family: 'Permanent Marker', 'Arizonia', 'Kalam', cursive !important;
        font-size: 3em;
    }

    .siteBackground {
        width: 100%;
        height: 100%;

        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;

        z-index: -1;

        // https://unsplash.com/photos/ee7NSnKigkA
        background-image: url('https://images.unsplash.com/photo-1557163123-abbafe113238?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=80');
        background-position: 50%;
    }

    .MasterContainer {
        padding-top: 5em;
    }
}

// @media screen and (device-max-width: 900px) {
//     .MasterContainer{
//         background-size: cover;  
//     }
// }